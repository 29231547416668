/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
const op = require('object-path');
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import { values } from 'appdir/main';

import Template from 'appdir/components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import StubBox from 'appdir/components/common-ui/StubBox';
import Favorites from 'appdir/components/common-ui/Favorites';
import MIHeaderContents from 'appdir/components/pages/MatchInsights/elements/MIHeaderContents';
import SearchBox from 'appdir/components/common-ui/SearchBox';
import DataTabs from 'appdir/components/common-ui/DataTabs';
import LeaderboardRow from './elements/LeaderboardRow';
import LeaderboardData from './elements/LeaderboardData';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';
import PathToTheFinal from 'appdir/components/common-ui/PathToTheFinal';
import { getQuerystringValues } from 'appdir/components/general/Util';
import { getQSParams } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import MeasurementUtils from 'appdir/lib/analytics';
import { doMeasurement } from 'appdir/components/general/Analytics';
import { isPostMatch } from 'appdir/components/pages/DrawsPage/DrawsUtils';

/**
 * -----------------------------------------------------------------------------
 * React Component: PowerIndexLeaderboard
 * -----------------------------------------------------------------------------
 */
/** convert tab values to the event ID
 *  to access to Tournament data */

const tabLookUp = {
	atp: 'MS',
	wta: 'LS',
};
export default class PowerIndexLeaderboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expired: false,
			startIndex: 0,
			endIndex: 31,
			loadMore_disabled: false,
			primary_sort: 'wpi_rank',
			secondary_sort: null,
			expand_fav: {},
			expand_full: {},
			resetSearch: false,
			appFavs: window.location.search ? getQSParams(window.location.search, 'filterPlayers').split(',') : [],
			defaultPlayer: window.location.search ? getQSParams(window.location.search, 'playerId') : false,
			selectedTab:
				(window.location.search && getQSParams(window.location.search, 'playerId').startsWith('wta')) ||
				(window.location.search &&
					getQuerystringValues(window.location.search.replace(/^\?/, '')).selectTab == 'wta')
					? 'wta'
					: 'atp',
		};
		this.increment = 32;
		this.loadMoreCounter = 0;
		this.pageViewMeasured = false;
		this.onEnter = this.onEnter.bind(this);
		this.tabSelect = this.tabSelect.bind(this);
		this.sortData = this.sortData.bind(this);
		this.loadMoreData = this.loadMoreData.bind(this);
		this.expandRow = this.expandRow.bind(this);
		this.updateAppFavs = this.updateAppFavs.bind(this);
		this.appShowFavs = window.location.search ? getQSParams(window.location.search, 'showFavs') == 'true' : false;

		logger.log('[PowerIndexLeaderboard] constructor - state:%o, props: %o', this.state, this.props);
		logger.log(
			'[PowerIndexLeaderboard] constructor - appShowFavs:%o, appFavs: %o',
			this.appShowFavs,
			this.state.appFavs
		);

		this.initLoad = true;
		this.dataLoaded = false;
	}

	componentDidMount() {}

	componentWillUnmount() {
		this.props.unmount();
		this.props.clearTournament();
		this.props.pathToTheFinalUnmount();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.status == 'loaded' && this.initLoad) {
			this.initLoad = false;
			this.props.getPowerIndex(tabLookUp[this.state.selectedTab]);
			this.props.getPlayerList();
			this.props.getPowerIndexTrends();

			/** if Draw Analysis is enabled, fetch draws data
			 *  to show or hide drawLabel
			 */
			if(this?.props?.enabled?.drawAnalysis) {
				this.props.getDraws(tabLookUp[this.state.selectedTab]);
			}
		}

		if (
			this.props?.players?.status == 'loaded' &&
			this.props?.powerIndex?.status == 'loaded' &&
			!this.state.playerList &&
			((this.props?.drawData?.status == "loaded" && this.props?.enabled?.drawAnalysis) || !this.props?.enabled?.drawAnalysis)
		) {
			// load players list for searchbox
			if (this.props?.players?.data?.length > 0 && this.props?.powerIndex?.data?.length > 0 ) {
				let playerList = [];
				this.dataLoaded = true;

				this.props.players.data.map(player => {
					playerList.push({
						firstName: player.first_name,
						lastName: player.last_name,
						shortName: player.tv_name,
						country: player.country,
						countryName: player.country_long,
						id: player.id,
					});
				});

				/** find each player's event status to display or hide draw analysis label in the row */
				if(this.props.enabled?.drawAnalysis) {
					this.getPlayerEventStatus(this.props.powerIndex.data);
				}

				/** store Power Index for atp or wta as Tournament data only holds one set of data */
				this.setState({
					playerList,
					powerIndexData: {
						[this.state.selectedTab]: this.props?.powerIndex.data,
					},
				});
			}
		}

		if (this.props.status == 'loaded' && this.props.currentDay && !this.pageViewMeasured) {
			this.pageViewMeasured = true;
			// first time in, do a pageview
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: values.powerIndexTitle,
				//day: this.props.currentDay['tournament'],
			});
		}

		if (!this.state.sortedList && this.state.powerIndexData?.[this.state.selectedTab] && this.state.playerList) {
			logger.log('[PowerIndexLeaderboard] componentDidUpdate updateSortedList initial');
			let list = this.state.powerIndexData[this.state.selectedTab];

			this.updateSortedList(list, true);
		}

		/** sorting is changed */
		if (this.state.primary_sort !== prevState.primary_sort) {
			logger.log('[PowerIndexLeaderboard] componentDidUpdate primary_sort changed');
			this.updateSortedList(this.state.sortedList);
		}

		/** selected Tab is changed */
		if (prevState.selectedTab !== this.state.selectedTab) {
			if(this?.props?.enabled?.drawAnalysis) {
				this.props.getDraws(tabLookUp[this.state.selectedTab]);
			}

			if (this.state.powerIndexData?.[this.state.selectedTab]) {
				let list = this.state.powerIndexData?.[this.state.selectedTab];
				this.updateSortedList(list, true);
			} else {
				this.props.getPowerIndex(tabLookUp[this.state.selectedTab]);
			}

			/** clear the defaultTop position */
			if (this.props?.pathToTheFinal?.defaultTop > 0) {
				this.props.pathToTheFinalUnmount();
			}
		}

		/** drawData and/or power index data are updated 
		 * - check each player's event status for draw analysis display */
		if(this.props.enabled?.drawAnalysis && 
			this.props?.drawData?.status == "loaded" && 
			(
				(this.props?.drawData?.data?.eventId !== prevProps?.drawData?.data?.eventId) || 
				(this.state?.powerIndexData?.[this.state?.selectedTab] !== prevState?.powerIndexData?.[this.state?.selectedTab])
			) &&
			this.props?.drawData?.data?.eventId == tabLookUp[this.state.selectedTab] &&
			this.state?.powerIndexData?.[this.state?.selectedTab]
		) {
			this.getPlayerEventStatus();
		}

		/** store the selected event power index in state as Tournamet data only holds one event and overwrites it  */
		if (
			this.dataLoaded &&
			this.props?.powerIndex?.data !== prevProps?.powerIndex?.data &&
			!this.state.powerIndexData?.[this.state.selectedTab] &&
			this.props?.powerIndex?.data?.[0]?.playerid?.startsWith(this.state.selectedTab)
		) {
			let newState = {
				...this.state,
				powerIndexData: {
					...this.state.powerIndexData,
					[this.state.selectedTab]: this.props.powerIndex.data,
				},
			};

			logger.log('[PowerIndexLeaderboard] componentDidUpdate - newstate:%o, this: %o', newState, this);
			this.setState(
				{
					powerIndexData: {
						...this.state.powerIndexData,
						[this.state.selectedTab]: this.props.powerIndex.data,
					},
				},
				() => {
					let list = this.props.powerIndex.data;
					this.updateSortedList(list, true);
				}
			);
		}

		/** when the Path To The Final overlay is closed, scroll to the previously stayed position */
		if (
			this.props.pathToTheFinal?.defaultTop &&
			this.props.pathToTheFinal?.display !== prevProps.pathToTheFinal?.display &&
			!this.props.pathToTheFinal?.display
		) {
			window.scrollBy(0, this.props.pathToTheFinal.defaultTop);
		}

		logger.log('[PowerIndexLeaderboard] componentDidUpdate - this: %o', this);
	}

	sendMetrics = () => {
		doMeasurement(values.powerIndexTitle, 'Learn More', []);
	};

	/***
	 * onEnter:  callback for the search filter box.
	 *
	 *  the id property names mismatch b/w SearchBox player list
	 *  and tournament playerList, tour_id vs id vs player_id
	 */
	onEnter(value) {
		logger.log('[PowerIndexLeaderboard] onEnter - value:%o', value);

		let searchedId = value?.tour_id;

		if (value !== '' && !searchedId) {
			searchedId = value.id;
		}

		let shouldSwitchTab = !searchedId?.startsWith(this.state.selectedTab);

		let filteredList =
			value !== ''
				? this.state.sortedList.filter((item, index) => {
						return item.playerid == searchedId;
				  })
				: [];

		// if user cleared out the search field, reset the list
		if (value !== '') {
			// set the sorted list to what was found in the filter
			let oppoTab = this.state.selectedTab == 'atp' ? 'wta' : 'atp';

			this.setState(
				{
					sortedList: filteredList,
					loadMore_disabled: true,
					resetSearch: false,
					defaultPlayer: searchedId,
					selectedTab: shouldSwitchTab ? oppoTab : this.state.selectedTab,
				},
				() => {
					if (!window.webview) {
						//let { favourites } = this.props;
						const favourites = op.get(this.props, 'favourites', {});

						if (favourites.show && favourites.players.includes(searchedId)) {
							this.expandRow(searchedId, true, 'fav');
						} else {
							this.expandRow(searchedId, true, 'full');
						}
					}
				}
			);
		} else {
			/** reset the list
			 *  powerIndex data is stored in the local state under each selected tab key
			 *  as Tournament data won't hold two sets of data
			 */
			let list = this.state?.powerIndexData?.[this.state.selectedTab];

			if (this.state.defaultPlayer) {
				this.props?.history?.replace(`${window.webview ? 'webview' : ''}/en_GB/powerindex/index.html`);
			}

			this.setState(
				{
					loadMore_disabled: false,
					resetSearch: false,
					defaultPlayer: false,
				},
				() => {
					this.updateSortedList(list, true);
				}
			);
		}
	}

	/**
	 * tabSelect:  callback for the ladies/gents tabs
	 */
	tabSelect(which) {
		logger.log('[PowerIndexLeaderboard] tabSelect - which:%o', which);
		if (this.state.selectedTab !== which) {
			this.setState({
				selectedTab: which,
				startIndex: 0,
				endIndex: 31,
				loadMore_disabled: false,
				primary_sort: 'wpi_rank',
				secondary_sort: null,
				expand_fav: {},
				expand_full: {},
				resetSearch: true,
			});
		}

		let measureArgs = [];
		let contextData = [{ item: which }];

		if (values.webview) {
			measureArgs = [which];
			contextData = [];
		}
		doMeasurement(values.powerIndexTitle, 'Tab Selection', measureArgs, contextData);
	}



	/** 
	 *  
	 *  order:
	 *  favourable to neutral to difficult
	 * 	
	 * 	in case some players may not have Draws Analysis data,
	 *  set the value larger than other label to push them down
	 */
	getDrawAnalysisSortingOrder = (label) => {
		switch(label) {
			case "favourable":
				return "aaa";
			case "neutral":
				return "bbb";
			case "difficult":
				return "ccc";
				default:
					return "zzz";
		}
	}

	/**
	 * updateSortedList:  does the actual sort on the power index list and also adds additional player information
	 * if it is the intial sort.
	 * @param {*} list
	 * @param {*} initial
	 */
	updateSortedList(list, initial = false) {
		let sortedList = Object.assign([], list);
		let newSortedList = [];

		if (!initial) {
			if (this.state.primary_sort && this.state.secondary_sort) {

				if (this.state?.primary_sort == "drawAnalysis") {
					sortedList.sort((a, b) => {
						let num1, num2;

						num1 = a[this.state.primary_sort]?.["drawLabel"];
						num2 = b[this.state.primary_sort]?.["drawLabel"];
						
						/** order:
	 					*  favourable to neutral to difficult 
						*  drawLevel smaller to larger within the same difficulty
						*/
						num1 = this.getDrawAnalysisSortingOrder(num1);
						num2 = this.getDrawAnalysisSortingOrder(num2);
						
						if (num1 === num2) {
							return a[this.state.secondary_sort]?.['drawLevel'] < b[this.state.secondary_sort]?.['drawLevel']
							? -1
							: 1
						} else {
							return num1 > num2 ? 1 : -1;
						}
					}
					);
				} else {
					sortedList.sort((a, b) =>
						a[this.state.primary_sort] > b[this.state.primary_sort]
							? 1
							: a[this.state.primary_sort] === b[this.state.primary_sort]
							? a[this.state.secondary_sort] > b[this.state.secondary_sort]
								? 1
								: -1
							: -1
					);
				}
			} else if (this.state.primary_sort) {

				sortedList.sort((a, b) => {
					
					let val1 = a[this.state.primary_sort];
					let val2 = b[this.state.primary_sort];

					/** if the country name is not listed, push the row towards bottom */
					if (this.state?.primary_sort == "countryName") {
							val1 = val1 == "" ? "zzz" : val1;
							val2 = val2 == "" ? "zzz" : val2;
					}

					return val1 > val2 ? 1 : -1
			})
				
			}
		} else {
			// add player data to sortedList
			sortedList.map((pi_player, index) => {
				let playerData = this.state.playerList.find(player => {
					return player.id == pi_player.playerid;
				});

				// logger.log('[PowerIndexLeaderboard] componentDidUpdate playerData: %o, index:%o', playerData, index);

				if (playerData) {
					sortedList[index]['firstName'] = playerData.firstName;
					sortedList[index]['lastName'] = playerData.lastName;
					sortedList[index]['fullName'] = playerData.firstName + ' ' + playerData.lastName;
					sortedList[index]['shortName'] = playerData.shortName;
					sortedList[index]['countryName'] = playerData.countryName;
					sortedList[index]['country'] = playerData.country;
					// logger.log('[PowerIndexLeaderboard] componentDidUpdate player data added:%o', sortedList[index]);

					newSortedList.push(sortedList[index]);
				}
			});
		}
		this.setState(
			{
				sortedList: initial ? newSortedList : sortedList,
				playersAdded: true,
				resetSearch: false,
			},
			() => {
				// logger.log(
				// 	'[PowerIndexLeaderboard] updateSortedList - sortedList:%o, initial%o',
				// 	this.state.sortedList,
				// 	initial
				// );

				if (this.state.defaultPlayer && initial) {
					let searchData = this.state.playerList.find(player => {
						return player.id == this.state.defaultPlayer
							? {
									country: player.country,
									countryName: player.country_long,
									firstName: player.first_name,
									id: player.id,
									lastName: player.last_name,
									shortName: player.tv_name,
							  }
							: null;
					});
					logger.log('[PowerIndexLeaderboard] updateSortedList - searchData:%o', searchData);
					if (searchData) {
						this.onEnter(searchData);
					}
				}
			}
		);
	}

	/**
	 * sortData:  callback when user clicks a column to sort
	 * @param {*} which
	 */
	sortData(which) {
		logger.log('[PowerIndexLeaderboard] sortData - which:%o', which);
		let primary_sort;
		let secondary_sort;

		switch (which) {
			case 'powerrank':
				primary_sort = 'wpi_rank';
				secondary_sort = null;
				break;
			case 'atprank':
				primary_sort = 'tour_rank';
				secondary_sort = null;
				break;
			case 'playername':
				primary_sort = 'lastName';
				secondary_sort = 'firstName';
				break;
			case 'country':
				primary_sort = 'countryName';
				secondary_sort = null;
				break;
			case 'aidraw':
				primary_sort = 'drawAnalysis';
				secondary_sort = 'wpi_rank';
				break;
			default:
				primary_sort = 'wpi_rank';
				secondary_sort = null;
				break;
		}

		this.setState({
			primary_sort,
			secondary_sort,
		});
	}

	/**
	 * getRowData:  gets the data to be passed to the table row component
	 * @param {*} data
	 * @returns
	 */
	getRowData(data) {
		let player = this.state.playerList.find(p => {
			return p.id == data.playerid;
		});
		// logger.log('[PowerIndexLeaderboard] getRowData - data:%o, player:%o', data, player);

		return {
			type: 'row',
			atpRank: data.tour_rank,
			rank: data.wpi_rank,
			movement: data.wpi_rank_change ? data.wpi_rank_change : 0,
			flagPath: this.props?.flagImagePathSmall,
			countryCode: op.get(data, 'country', ''),
			countryName: op.get(data, 'countryName', ''),
			shortName: op.get(data, 'shortName', ' - '),
			playerName: op.get(data, 'fullName', ' - '),
			playerId: data.playerid,
			drawAnalysis: data?.drawAnalysis,
			playerEventStatus: this.state?.playerEventStatus?.[data.playerid],
			enabled: this.props?.enabled?.drawAnalysis
		};
	}

	/**
	 * getContentData:  gets the data to be passed to the table data component. (shown when user clicks a row)
	 * @param {*} piData
	 * @param {*} trendData
	 * @param {*} playerDrawAnalysisData
	 * @returns
	 */
	getContentData(piData, trendData, playerDrawAnalysisData) {
		//logger.log('[PowerIndexLeaderboard] getContentData - piData:%o', piData);
		const playerMatches = op.get(this.props, `playerMatchesData.${piData.playerid}.result.matches`, []);

		let determiningFactors = [
			{ type: 'single', title: 'Recent Performance', value: piData.performance_score },
			{ type: 'single', title: 'Media Volume', value: piData.volume_score },
			{ type: 'double', title: 'Sentiment', value: piData.sentiment },
		];

		let labels = trendData.trend.map(data => {
			return moment(data.date)
				.tz('Europe/London')
				.format('M/D');
		});
		let wpi_series = trendData.trend.map(data => {
			return -data.wpi_rank;
		});
		let atp_series = trendData.trend.map(data => {
			return -data.tour_rank;
		});

		logger.log('[PowerIndexLeaderboard] getContentData - playerMatches:%o', playerMatches);

		const getWinIndicator = () => {
			if (playerMatches.length > 0) {
				const match = playerMatches[0];
				if (match[`team${match.winner}`].idA == trendData.bio.player_id) {
					return 'W';
				} else {
					return 'L';
				}
			}
		};

		const matchWon = index => {
			if (matchesPlayed.length > 0) {
				const match = matchesPlayed[index];
				if (match[`team${match.winner}`].idA == trendData.bio.player_id) {
					return true;
				} else {
					return false;
				}
			}
		};

		const matchesPlayed = playerMatches.filter((match, index) => {
			return match.eventCode == 'MS' || match.eventCode == 'LS' ? match.epoch : null;
		});

		let rankOverTime = {
			graphData: {
				labels,
				series: [
					{
						name: 'atp-series',
						className: 'ct-series-a atp-series',
						data: atp_series,
					},
					{
						name: 'powerrank-series',
						className: 'ct-series-b  powerrank-series',
						data: wpi_series,
					},
				],
			},
			/**  subtract 5 hours from match end date to force all matches to act like the tournament day */
			matchesPlayed: matchesPlayed.map((match, index) => {
				return {
					matchDate: moment(match.epoch)
						.subtract(5, 'hours')
						.tz('Europe/London')
						.format('M/D'),
					won: matchWon(index),
				};
			}),
			/**  subtract 5 hours from match end date to force all matches to act like the tournament day */
			lastMatchPlayed:
				playerMatches.length > 0
					? moment(playerMatches[0].epoch)
							.subtract(5, 'hours')
							.tz('Europe/London')
							.format('M/D')
					: '',
			winIndicator: getWinIndicator(),
			event: this.state.selectedTab,
		};

		logger.log('[PowerIndexLeaderboard] getContentData - contentData:%o', { determiningFactors, rankOverTime });

		return {
			determiningFactors,
			rankOverTime,
			powerrank: piData.wpi_rank,
			playerImageData: { 'player-image': this.props.playerImagePath.replace('<playerid>', piData.playerid) },
			playerStatusData: op.get(this.props, `playerStatusData.${piData.playerid}.result`, false),
			matchInsightsMatches: op.get(this.props, `matchInsightsMatchesData.result`, false),
			playerDrawAnalysisData,
		};
	}

	/**
	 * loadMoreData:  handler for the load more button
	 * @param {*} listLength
	 */
	loadMoreData(listLength) {
		const endIndex = op.get(this.state, 'endIndex');
		const newEnd = endIndex + this.increment < listLength ? endIndex + this.increment : listLength - 1;
		logger.log(
			'[PowerIndexLeaderboard] loadMoreData - listLength:%o, endIndex:%o, newEndIndex:%o',
			listLength,
			endIndex,
			newEnd
		);

		if (!this.state.loadMore_disabled) {
			this.setState(
				{
					endIndex: newEnd,
					loadMore_disabled: newEnd == listLength - 1,
				},
				() => {
					logger.log('[PowerIndexLeaderboard] loadMoreData - state:%o', this.state.endIndex);
					this.loadMoreCounter++;
					//MeasurementUtils.dispatchMeasurementCall('loadMore', this.loadMoreCounter);
					let measureArgs = [{ action: this.loadMoreCounter }];
					let contextData = [];

					if (values.webview) {
						measureArgs = [this.loadMoreCounter];
						contextData = [];
					}
					doMeasurement(values.powerIndexTitle, 'loadMore', measureArgs, contextData);
				}
			);
		}
	}

	/**
	 * expandRow:  callback for when user expands a row
	 * @param {*} playerId
	 * @param {*} toggle
	 * @param {*} type
	 */
	expandRow(playerId, toggle, type) {
		logger.log('[PowerIndexLeaderboard] expandRow - playerId:%o, toggle:%o, type:%o', playerId, toggle, type);

		const curState = Object.assign({}, this.state[`expand_${type}`]);

		this.setState(
			{
				expand_fav: {},
				expand_full: {},
				[`expand_${type}`]: {
					//...curState,
					[playerId]: toggle,
				},
			},
			() => {
				// if toggle = true get player matches data
				if (toggle) {
					/** draw analysis data for the player */
					this.props.getDrawAnalysis(tabLookUp[this.state.selectedTab], playerId);

					this.props.getPlayerMatches(playerId);

					this.props.getPlayerStatus(playerId);

					/** get the available match insights */
					this.props.getMatchInsightsAvailable();

					/** save the scroll position so when the help overlay
					 *  is closed, the user stays at the same position
					 */
					let scrollTop =
						window.pageYOffset !== undefined
							? window.pageYOffset
							: (document.documentElement || document.body.parentNode || document.body).scrollTop;
					this.props.pathToTheFinalUpdate({ defaultTop: scrollTop });
				} else {
					/** clear position */
					this.props.pathToTheFinalUpdate({ defaultTop: 0 });
				}
			}
		);
	}

	isWebviewFav(playerId) {
		logger.log(
			'[PowerIndexLeaderboard] - isWebviewFav - appFavs:%o, %o:%o',
			this.state.appFavs,
			playerId,
			this.state.appFavs.indexOf(playerId) !== -1
		);
		return this.state.appFavs.indexOf(playerId) !== -1;
	}

	updateAppFavs(playerId, shouldAdd) {
		logger.log('[PowerIndexLeaderboard] - updateAppFavs - playerId:%o', playerId, shouldAdd);
		let newFavs = Object.assign([], this.state.appFavs);
		//logger.log('[PowerIndexLeaderboard] - updateAppFavs - current state.appFavs:%o, newAppFavs:%o',this.state.appFavs, newFavs);

		if (shouldAdd) {
			newFavs.push(playerId);
		} else {
			newFavs = this.state.appFavs.filter(fav => fav !== playerId);
		}

		//logger.log('[PowerIndexLeaderboard] - updateAppFavs before state change - newFavs:%o',newFavs);

		this.setState(
			{
				appFavs: newFavs,
			},
			() => {
				logger.log('[PowerIndexLeaderboard] - updateAppFavs - new appFavs:%o', this.state.appFavs);
			}
		);
	}

	/** if the power index data is passed, use it */
	getPlayerEventStatus = (data) => {
		let drawData = this.props?.drawData;
		let piData = data ? data : this.state?.powerIndexData?.[this.state?.selectedTab];
		let latestMatchStatus = {};
		/** if there are drawData for the selected event, filter the match data */
		if (
			drawData?.status == "loaded" && 
			drawData?.data?.eventId == tabLookUp[this.state?.selectedTab] && // make sure drawsData is for the selected Tab's data
			piData?.length > 0 
		) 
		{
			piData.forEach((player, index) => {
				let piPlayer = player.playerid;
				let foundMatches = [];
				let curMatch = {};
				let playerEventStatus = 'in';

				foundMatches = drawData?.data?.matches.filter((match, i) => {
					return match.team1.idA == piPlayer || match.team2.idA == piPlayer;
				})

				if (foundMatches.length > 0) {
					curMatch = foundMatches[foundMatches.length - 1];

					if(curMatch.winner && curMatch.roundCode == "F") {
						/** if the final round, find if the player won or out */
						let teamObj = `team${curMatch.winner}`;
						playerEventStatus = curMatch[teamObj].idA == piPlayer ? 'won' : 'out';
					} else if (isPostMatch(curMatch)) {
						/** the latest match is over - is the player In or out */
						let teamObj = `team${curMatch.winner}`;
						playerEventStatus = curMatch[teamObj].idA == piPlayer ? 'in' : 'out';
					}
				}
				
				latestMatchStatus[piPlayer] = playerEventStatus;
			})

			this.setState({
				playerEventStatus: latestMatchStatus
			})
		}
		logger.log('[PowerIndexLeaderboard] - getPlayerEventStatus - latestMatchStatus:%o',latestMatchStatus);
	}

	/**
	 * isDataReady:  determines if the data is ready for rendering
	 * @returns
	 */
	isDataReady() {
		return (
			this.state.playerList &&
			this.state.playersAdded &&
			this.state.powerIndexData?.[this.state.selectedTab] &&
			(op.get(this.props, 'powerIndexTrendingData.result') ? true : false)
		);
	}

	/**
	 * renderTableList: formats the jsx for rendering the table list
	 * @param {*} dataList
	 * @param {*} expandType
	 * @returns
	 */
	renderTableList(dataList, expandType) {
		return dataList.map((row, index) => {
			let piData = this.getRowData(row);
			let selectedPlayerId = row.playerid;
			let trendData = this.props.powerIndexTrendingData?.result?.[selectedPlayerId];

			/** make sure drawAnalysisData in Tounament Data is for the selected player */
			let playerDrawAnalysisData =
				this.props?.drawAnalysisData?.data?.playerId == selectedPlayerId
					? this.props?.drawAnalysisData?.data
					: null;

			let expand = op.get(this.state, `expand_${expandType}.${row.playerid}`, false) ? true : false;
			let rowData = {
				...piData,
				expand,
				expandType,
				appFavs: this.state.appFavs,
				expandCallback: this.expandRow,
				appFavCallback: this.updateAppFavs,
			};
			let contentData =
				row &&
				expand &&
				op.get(this.props, `playerMatchesData.${row.playerid}.result.matches`, false) &&
				op.get(this.props, `playerStatusData.${row.playerid}.result.matches`, false)
					? this.getContentData(row, trendData, playerDrawAnalysisData)
					: false;

			// logger.log('[PowerIndexLeaderboard] renderTableList - contentData:%o, row:%o, expand:%o, statusData:%o', contentData, row, expand, statusData);

			return index <= this.state.endIndex ? (
				<LeaderboardRow key={row.playerid} data={rowData}>
					{contentData && expand ? (
						<ErrorBoundary
							message={`Additional ${values.powerIndexTitle} data is unavailable for this player`}>
							<LeaderboardData data={contentData} />
						</ErrorBoundary>
					) : null}
				</LeaderboardRow>
			) : null;
		});
	}

	/**
	 *  If no one has drawAnalysis label, hide the header text, AI Draw Analysis
	 * @param {*} dataList
	 * @returns
	 */
	hideAiDrawHeader = dataList => {
		let hideAidrawHdr = false;
		if (dataList?.length > 0) {
			let dataAry = [...dataList];
			let tmp = [];

			tmp = dataAry.filter(item => {
				return item?.drawAnalysis?.drawLabel !== '';
			});

			hideAidrawHdr = tmp.length == 0;
		}

		/** if everyone is done playing the event, hide the draw analysis header */
		if(this.state?.playerEventStatus && this?.props?.enabled?.drawAnalysis) {
			for(let key in this.state?.playerEventStatus) {
				if(this.state?.playerEventStatus[key] == "won") {
					return hideAidrawHdr = true;
				}
			}
		}

		return hideAidrawHdr;
	};

	/**
	 * renderPageContent:  renders the overall page structure.  calls other render type functions.
	 * @param {*} header_propsData
	 * @returns
	 */
	renderPageContent(header_propsData) {
		const favourites = op.get(this.props, 'favourites', {});

		logger.log('[PowerIndexLeaderboard] renderPageContent - this.state.appFavs:%o', this.state.appFavs);

		let headerDate = op.get(this.props, `tournament.data.powerIndex.publicationTimestamp`);
		let dataList = op.get(this.state, `sortedList`, []);
		let favoriteList =
			favourites.players && favourites.players.length > 0 && favourites.show
				? dataList.filter((item, index) => {
						return favourites.players.includes(item.playerid);
				  })
				: [];

		if (window.webviewPlatform) {
			favoriteList =
				this.state.appFavs.length > 0 && this.appShowFavs
					? dataList.filter((item, index) => {
							return this.state.appFavs.includes(item.playerid);
					  })
					: [];
		}

		let searchData =
			this.state.defaultPlayer && this.state.playerList
				? this.state.playerList.find(player => {
						return player.id == this.state.defaultPlayer;
				  })
				: null;

		// logger.log('[PowerIndexLeaderboard] renderPageContent - searchData:%o', searchData);

		let searchValue = searchData ? searchData.shortName : '';
		// logger.log('[PowerIndexLeaderboard] renderPageContent - searchValue:%o', searchValue);

		// logger.log('[PowerIndexLeaderboard] renderPageContent - favoriteList:%o', favoriteList);

		// logger.log('[PowerIndexLeaderboard] renderPageContent - dataList:%o', dataList);

		let playerSearchList = this.state.playerList
			? this.state.playerList.filter(player => {
					return player.id.startsWith(this.state.selectedTab) !== -1;
			  })
			: [];

		if (this.props.stubs && this.props.stubs?.powerindex?.stub === 'stub') {
			return (
				<div id="powerindex-leaderboard" className="content-main">
					<StubBox attributes={{ message: this.props.stubs.powerindex.text, style: 'centerV' }} />
				</div>
			);
		} else {
			return (
				<div id="powerindex-leaderboard" className="content-main">
					{this.state.error ? (
						<StubBox attributes={{ message: `${values.powerIndexTitle} is unavailable at this time.` }} />
					) : (
						<ErrorBoundary
							message={`${values.powerIndexTitle} is unavailable at this time.`}
							showStub={true}>
							<div className="column-layout">
								<MIHeaderContents
									data={{
										type: 'power-rankings',
										headerTitle: header_propsData.title,
										headerSubTitle: header_propsData.helper,
										headerDate: headerDate,
									}}>
									<div className="player-search">
										<SearchBox
											staticData={playerSearchList}
											onEnter={e => this.onEnter(e)}
											reset={this.state.resetSearch}
											value={this.state.defaultPlayer ? searchValue : ''}
										/>
										{window.webviewPlatform ? null : <Favorites />}
									</div>
									<DataTabs
										data={{
											className: '',
											callback: this.tabSelect,
											tabsList: [
												{
													selected: this.state.selectedTab == 'atp',
													id: 'atp',
													label: "Gentlemen's",
												},
												{
													selected: this.state.selectedTab == 'wta',
													id: 'wta',
													label: "Ladies'",
												},
											],
										}}
									/>
								</MIHeaderContents>
							</div>
							<div className="column-layout">
								<div className="leaderboard-table">
									<LeaderboardRow
										data={{
											type: 'header',
											sortCallback: this.sortData,
											selectedTab: this.state.selectedTab,
											hideAidrawHdr: this.hideAiDrawHeader(dataList),
											enabled: this?.props?.enabled?.drawAnalysis,
											playerEventStatus: this.state?.playerEventStatus ? Object.values(this.state?.playerEventStatus)?.[0] : null
										}}
									/>

									{/* favorites */}
									{favoriteList.length > 0 ? (
										<>
											<LeaderboardRow data={{ type: 'subheader' }}>Favourites</LeaderboardRow>
											{this.isDataReady() ? this.renderTableList(favoriteList, 'fav') : null}
										</>
									) : null}
									{/* full leaderboard */}
									<LeaderboardRow data={{ type: 'subheader' }}>Full Leaderboard</LeaderboardRow>
									{this.isDataReady() ? this.renderTableList(dataList, 'full') : null}
								</div>
								<Button
									className={`solid ${this.state.loadMore_disabled ? 'disabled' : ''}`}
									onClick={
										!this.state.loadMore_disabled ? () => this.loadMoreData(dataList.length) : null
									}>
									Load More
								</Button>
								<div className="helper-wrapper">
									<div className="helper-title">{values.powerIndexTitle}</div>
									<div className="helper-text">
										The {values.powerIndexTitle} is an AI-powered analysis of player momentum. It
										combines advanced statistical analysis and the natural language processing of
										IBM Watson to mine millions of expert opinions and performance metrics,
										quantifying each player's momentum from tournament to tournament, and match to
										match. Click{' '}
										<WimLink
											to="https://www.ibm.com/uk-en/campaign/wimbledon"
											external={true}
											style={'white'}>
											<span className="bold-link" onClick={() => this.sendMetrics()}>
												here
											</span>
										</WimLink>{' '}
										to learn more.
									</div>

									{/* learn more link used to be https://www.ibm.com/wimbledon  */}
									{/* <div className="helper-action">
											<WimLink to="https://www.ibm.com/wimbledon/" external={true}>
												Learn more
											</WimLink>
										</div> */}
								</div>

								{/** load Path to the Final */
								this.props?.pathToTheFinal?.display && (
									<PathToTheFinal
										appsData={{
											appFavs: this.state.appFavs,
											appFavCallback: () => this.updateAppFavs,
										}}
									/>
								)}
							</div>
						</ErrorBoundary>
					)}
				</div>
			);
		}
	}

	render() {
		logger.log('[PowerIndexLeaderboard] render - window.webviewPlatform:%o', window.webviewPlatform);
		logger.log('[PowerIndexLeaderboard] render - props:%o, this.state:%o', this.props, this.state);

		let header_propsData = {
			headerType: 'powerindex',
			title: values.powerIndexTitle,
			shortTitle: values.powerIndexTitle,
			metaTitle: values.powerIndexTitle,
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			helper: "The world's first AI-powered measurement of player momentum",
		};

		if (this.props.status == 'loaded' && !window.webviewPlatform) {
			return (
				<Template id="powerindex" className="scorespage">
					<div className="powerindex-background" />
					<Header attributes={header_propsData} />

					<PageHeader attributes={header_propsData} />
					{this.renderPageContent(header_propsData)}
					<Footer />
				</Template>
			);
		} else if (this.props.status == 'loaded' && window.webviewPlatform) {
			return (
				<>
					<PageHeader attributes={{ isWebview: true }} />
					{this.renderPageContent(header_propsData)}
				</>
			);
		} else if (this.props.status !== 'loaded') {
			logger.log('[PowerIndexLeaderboard] webviewPlatform', this.webviewPlatform);
			if (!this.webviewPlatform) {
				return (
					<>
						<Template id="powerindex" className="scorespage">
							<Header attributes={header_propsData} />

							<PageHeader attributes={header_propsData} />

							<div className="content-main">
								<LoadingIndicator />
							</div>
							<Footer />
						</Template>
					</>
				);
			} else {
				return (
					<>
						<PageHeader attributes={{ isWebview: true }} />
						<div className="content-main">
							<LoadingIndicator />
						</div>
					</>
				);
			}
		} else {
			return null;
		}
	}
}

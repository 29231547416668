/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';

import { loggedIn } from 'appdir/components/general/Util/Role';
import { getErrorMessage } from 'appdir/components/general/Util/Error';
import SiteMap from 'appdir/components/general/SiteMap';
import Template from 'appdir/components/Template';
import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import LoginRequired from 'appdir/components/common-ui/LoginRequired';

import { TicketsContext } from './context';
import TicketFilters from './elements/TicketFilters';
import DesktopTicketView from './elements/DesktopTicketView';
import AppPromo from './elementsFunc/AppPromo';
import AppLink from './elementsFunc/AppLink';
import { updateTicketLabels, updateCourtLabels, updateTicketDay } from '../../pages/Tickets/Data';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';

/**
 * -----------------------------------------------------------------------------
 * React Component: Content Page
 * -----------------------------------------------------------------------------
 */
const TICKET_ROLEID = 1000090;

const desktopMatch = window.matchMedia('(min-width: 1025px)').matches;

const mapStateToProps = (state, props) => {
	return {
		gigya: state['Gigya'],
		...state['Tickets'],
		stubs: op.get(state['Config'].stubPages, 'pde', null),
		orderedCourts: op.get(state['Config'], 'ticketConfig.ticketCourts', null),
		ticketLabels: op.get(state['Config'], 'ticketLabels', null),
		configTickets: op.get(state['Config'], 'tickets', null),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Tickets.mount()),
	unmount: () => dispatch(deps.actions.Tickets.unmount()),
	callSpectator: (service, id) => dispatch(deps.actions.Tickets.callSpectator(service, id)),
	callSpectatorAll: (service, accounts) => dispatch(deps.actions.Tickets.callSpectatorAll(service, accounts)),
	update: update => dispatch(deps.actions.Tickets.update(update)),
	showModal: data => dispatch(deps.actions.ConfirmationModal.toggleModal(data)),
});

class Tickets extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			filters: {},
			modalClosedByUser: false,
			ticketLength: '',
			resetClicked: false,
			ticketManagementChecked: false,
			ticketActionTabSelected: 'viewAll',
			displayAppPromo: false,
			actionsModalDisplay: false,
			initialLoad: true,
			dataLoad: '',
		};

		// this.dataLoad = '';

		this.onFilterUpdate = this.onFilterUpdate.bind(this);
		this.onViewUpdate = this.onViewUpdate.bind(this);
		this.onFilterReset = this.onFilterReset.bind(this);
		this.onModalClose = this.onModalClose.bind(this);
		//logger.log('[Tickets] constructor - state:%o', this.state);
	}

	componentDidMount() {
		logger.log('[Tickets] componentDidMount - state:%o', this.state);
	}

	componentDidUpdate(prevProps, prevState) {
		let currentUser = op.get(this.state, 'gigya.currentUser', {});

		logger.log(
			'[Tickets] componentDidUpdate - dataLoad:%o, user:%o state:%o prev:%o',
			this.state.dataLoad,
			op.get(currentUser, 'profile.lastName', ''),
			this.state,
			prevState
		);

		if (!loggedIn(currentUser) && this.state.dataLoad != '') {
			// this.dataLoad = '';
			this.setState({ dataLoad: '' });
			if (!this.state.initialLoad) this.setState({ initialLoad: true }); // reset on logout
			if (this.state.actionsModalDisplay) this.setState({ actionsModalDisplay: false }); // reset on logout

			this.props.update({
				spectatorId: null,
			});
		}

		if (
			(this.state?.tickets?.page == 'view' || this.state?.tickets?.page == 'return') &&
			this.state.dataLoad == '' &&
			((isEmpty(this.state.ticketData) &&
				this.state.spectatorId &&
				this.state.spectatorId != prevState.spectatorId) ||
				op.get(this.state, 'refreshData', false) === true)
		) {
			logger.log('[Tickets] componentDidUpdate load data - state:%o', this.state);

			this.setState({ dataLoad: 'loading' });
			// this.dataLoad = 'loading';
			this.callTickets();
		}
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newUser = op.get(nextProps, 'gigya.currentUser', {});
		let prevUser = op.get(prevState, 'gigya.currentUser', {});
		//get first spectatorId as default
		let spectatorAccounts = Tickets.getSpectatorAccounts(nextProps);
		let spectatorIndex = 0;
		let spectatorId = null;
		let refreshData = false;

		//set initial/default spectator
		if (spectatorAccounts) {
			spectatorAccounts.forEach((element, idx) => {
				if (element.value == nextProps.selectAcct) {
					spectatorIndex = idx;
				}
			});
		}

		if (nextProps.spectatorId) {
			spectatorId = nextProps.spectatorId;
		} else if (spectatorAccounts) {
			spectatorId = spectatorAccounts[spectatorIndex].value;
			refreshData = true;
		}

		logger.log('[Tickets] getDerivedStateFromProps - nextProps:%o', nextProps);

		if (!nextProps.ticketLabels) {
			return null;
		}

		if (nextProps?.tickets?.page == 'index') {
			return {
				...prevState,
				...nextProps,
			};
		}

		let updateState = newUser.UID != prevUser.UID ||
			spectatorId != prevState.spectatorId ||
			nextProps?.tickets?.page != prevState?.tickets?.page ||
			(op.get(nextProps, 'gigya.gigyaLoaded', false) && !op.get(prevState, 'gigya.gigyaLoaded', false))
			? {
					...prevState,
					...nextProps,
					lastUser: '',
					ticketData: [],
					ticketComplete: null,
					refreshData: refreshData,
					spectatorId: spectatorId,
					ticketLength: '',
			  }
			: null;

		return updateState;
	}

	/**
	 * get spectator accounts
	 */
	static getSpectatorAccounts(props) {
		let roles = op.get(props, 'gigya.currentUser.roles', []);
		let ticketRole = roles.filter(d => d.roleId == TICKET_ROLEID)[0];
		//let spectatorId = ticketRole ? ticketRole.otherId.filter(d => d.type == 'spectatorId')[0].value : null;

		if (ticketRole && ticketRole.otherId) {
			return ticketRole.otherId.filter(d => d.type == 'spectatorId');
		} else {
			return null;
		}
	}

	/**
	 * call ticket API
	 */
	callTickets() {
		//logger.log('[Tickets] callTickets - state:%o', this.state);
		this.props
			.callSpectatorAll('view', Tickets.getSpectatorAccounts(this.state))
			.then(data => {
				//logger.log('[Tickets] callTickets - data:%o', data);
				logger.log('[Tickets] callTickets - tickets:%o', data.tickets);
				data.tickets = updateTicketLabels(data.tickets, this.state.ticketLabels);
				data.tickets = updateCourtLabels(data.tickets, this.state.orderedCourts);
				data.tickets = updateTicketDay(data.tickets, this.state.tournStart, this.state.qualStart);
				// this.dataLoad = 'loaded';
				this.setState({ dataLoad: 'loaded' });

				// if initial load, check conditions. otherwise maintain current state value
				let tixManagementVal = this.state.initialLoad
					? data?.tickets?.length >= 30 && !window.matchMedia('(max-width: 1025px)').matches
					: this.state.ticketManagementChecked; // check size in real time on update (called when user closes modal resize close btn)
				this.setState({
					ticketData: data.tickets,
					ticketComplete: data.complete,
					time: {
						serverTime: data.serverTime,
						systemTime: data.systemTime,
					},
					refreshData: false,
					errorCode: data.error[0] && data.error[0].reason.code ? data.error[0].reason.code : null,
					ticketManagementChecked: tixManagementVal,
					initialLoad: false, // set intial load to false
				});
			})
			.catch(error => {
				logger.error('[Tickets] callTickets - error:%o', error);
				this.setState({
					ticketData: [],
					ticketComplete: null,
					time: {
						serverTime: null,
						systemTime: null,
					},
					refreshData: false,
					errorCode: error.code,
				});
			});
	}

	onModalClose() {
		this.setState({
			...this.state,
			modalClosedByUser: true,
		});
	}

	/**
	 * update filters in state
	 */
	onFilterUpdate(type, value) {
		// logger.log('[Tickets] onFilterUpdate - type:%o, value:%o', type, value);

		this.setState({
			...this.state,
			resetClicked: false,
			filters: {
				...this.state.filters,
				[type]: value,
			},
		});
	}

	/**
	 * set all filters to null
	 */
	onFilterReset() {
		this.setState({
			...this.state,
			resetClicked: true,
			filters: {
				action: null,
				date: null,
				court: null,
				status: null,
				type: null,
			},
		});
	}

	/**
	 * update view based on selected action and tickets
	 */
	onViewUpdate(which, data) {
		if (which == 'refresh') {
			this.setState({
				refreshData: true,
				ticketData: [],
				ticketComplete: null,
			});
		} else {
			this.setState({
				...this.state,
				view: which,
				viewData: data,
			});
		}
	}

	render() {
		logger.log('[Tickets] render - state:%o', this.state);

		let spectatorAccounts = Tickets.getSpectatorAccounts(this.state);

		let header_propsData = {
			headerType: this.state.category,
			title: 'Tickets',
			shortTitle: 'Tickets',
			metaTitle: 'Tickets',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scrollElem: '.content-main',
			titleElem: '.news--header',
			skipContent: true,
		};

		// filter out date from UGP days
		if (this.state.ticketData) {
			this.state.ticketData.map(e => {
				if (e.day === 'UGP') {
					e.source.event.startTime = undefined;
				}
			});
		}

		let contextValues = {
			config: op.get(this.state, 'pde.config', null),
			stubs: op.get(this.state, 'stubs', null),
			ticketData: op.get(this.state, 'ticketData', []),
			ticketComplete: op.get(this.state, 'ticketComplete', null),
			spectatorAccounts: spectatorAccounts,
			//spectatorId: op.get(this.state, 'spectatorId', null),
			userRoles: op.get(this.state, 'gigya.currentUser.roles', []),
			filters: op.get(this.state, 'filters', null),
			orderedCourts: op.get(this.state, 'orderedCourts', null),
			time: op.get(this.state, 'time', null),
		};

		//force ticketComplete to false if no ticket data
		//  triggers messaging to refresh in view if not ticketData for whatever reason
		if (contextValues.ticketData.length == 0 && contextValues.ticketComplete != null) {
			contextValues.ticketComplete = false;
		}

		logger.log('[Tickets] render - contextValues:%o', contextValues);

		return (
			<Template>
				<Header attributes={header_propsData} />
				<MainNav />
				<PageHeader attributes={header_propsData} />

				<div className="content-main content" id="main" tabIndex="0">
					<div className="column-layout ticketview content">
						<TicketsContext.Provider value={contextValues}>
							<LoginRequired roles={[TICKET_ROLEID]}>
								{allowed => {
									if (this.state?.tickets?.page == 'index') {
										return <AppLink />;
									} else if (this.state?.tickets?.page == 'app') {
										return <AppPromo />;
									} else if (
										this.state?.tickets?.page == 'view' ||
										this.state?.tickets?.page == 'return'
									) {
										if (allowed && spectatorAccounts && !this.state.errorCode) {
											// return this.renderTicketView();
											return (
												<Fragment>
													{/* ticket management -- not displayed if mobile or tablet */}
													{(this.state.ticketComplete || (!this.state.ticketComplete && this.state.ticketData?.length !== 0)) &&
													desktopMatch ? (
														<div>
															{this.state.ticketManagementChecked ? (
																<div className="tix-mgmt-screen-not-supported">
																	<div className="modal-overlay">
																		<div className="modal-wrapper">
																			<div
																				className={`modal-container my-wimbledon ${
																					this.state.className ? ` ${this.state.className}` : ''
																				}`}>
																				<CloseBtn
																					closeFn={() => {
																						this.setState({
																							refreshData: true,
																							ticketManagementChecked: false,
																						});
																					}}
																				/>
																				<div className="modal-title" style={{ color: '#4C1486 !important' }}>
																					<h4>SCREEN SIZE NOT SUPPORTED</h4>
																				</div>
																				<div className="modal-content">
																					This screen size is not supported. Please use a screen that is 1024 or
																					larger to manage multiple tickets or close to manage one ticket at a
																					time.
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															) : null}
															{this.state.ticketData?.length > 0 &&
															!this.state.actionsModalDisplay &&
															!this.state.displayAppPromo ? (
																<div className="ticket-mgmt-cntr">
																	<div className="ticket-mgmt-topLine">
																		<img className="ticket-mgmt-icon" src="/assets/images/tickets/List--checked.svg" />
																		<div className="ticket-mgmt-txt">
																			TICKET MANAGEMENT
																			<div className="ticket-mgmt-switch">
																				<label class="switch">
																					<input
																						type="checkbox"
																						tabIndex={0}
																						alt={`ticket management checkbox. currently ${
																							this.state.ticketManagementChecked
																								? 'toggled on'
																								: 'toggled off'
																						}. toggle to turn on or off ticket maangement view`}
																						title={`ticket management checkbox. currently ${
																							this.state.ticketManagementChecked
																								? 'toggled on'
																								: 'toggled off'
																						}. toggle to turn on or off ticket maangement view`}
																						checked={this.state.ticketManagementChecked}
																						onClick={() => {
																							this.setState({
																								ticketManagementChecked: !this.state
																									.ticketManagementChecked,
																							});
																						}}
																					/>
																					<span class="slider round"></span>
																				</label>
																			</div>
																		</div>
																	</div>

																	<div className="ticket-mgmt-subTxt">Manage multiple tickets easily</div>
																</div>
															) : null}
														</div>
													) : null}
													{this.state.actionsModalDisplay ||
													(!this.state.ticketComplete && this.state.ticketData?.length === 0) ||
													this.state.displayAppPromo ? null : (
														<TicketFilters
															onChange={this.onFilterUpdate}
															orderedCourts={op.get(this.state, 'orderedCourts', null)}
															modalClosed={op.get(this.state, 'modalClosedByUser', false)}
															onModalClose={this.onModalClose}
															onReset={this.onFilterReset}
															faqsUrl={this.state.configTickets.faqsUrl}
															onTicketActionTabSelected={val =>
																this.setState({ ticketActionTabSelected: val, resetClicked: true })
															}
															ticketActionTabSelected={this.state.ticketActionTabSelected}
															ticketManagementChecked={this.state.ticketManagementChecked}
															ticketLength={this.state.ticketData?.length}
														/>
													)}
													{this.state.dataLoad != 'loading' && !this.state.ticketComplete && !this.state.actionsModalDisplay ? (
														<div
															className="desktop-ticket-view-no-tix"
															style={{ marginTop: this.state.ticketManagementChecked ? '24px' : '0px' }}>
															It can take a few minutes for newly assigned tickets to be available. <br />
															You may{' '}
															<a href="#" onClick={() => this.onViewUpdate('refresh', null)}>
																click here
															</a>{' '}
															to refresh your view.
														</div>
													) : null}
													<DesktopTicketView
														context={contextValues}
														resetClicked={this.state.resetClicked}
														onUpdate={val => this.onViewUpdate(val)}
														onSetReset={() => this.setState({ resetClicked: false })}
														ticketLengthSet={this.state.ticketLength}
														onUpdateTicketsLength={val => this.setState({ ticketLength: val })}
														showModal={this.props.showModal}
														ticketActionTabSelected={this.state.ticketActionTabSelected}
														onTicketActionTabSelected={val =>
															this.setState({ ticketActionTabSelected: val, resetClicked: true })
														}
														ticketManagementChecked={this.state.ticketManagementChecked}
														onRefreshTicketData={() => {
															this.setState({ dataLoad: 'loading' });
															this.callTickets();
														}}
														onActionsModalDisplay={val => this.setState({ actionsModalDisplay: val })}
														actionsModalDisplay={this.state.actionsModalDisplay}
														onDisplayAppPromo={val => this.setState({ displayAppPromo: val, actionsModalDisplay: val })}
													/>
												</Fragment>
											)
										} else if (allowed && this.state.errorCode == 'E3200') {
											return (
												<div className="four-col body h3 bottom-margin center">
													<div style={{ margin: '30px 0 15px 0' }}>
														Unfortunately there was an issue completing your ticket
														registration. Please try again.
													</div>
												</div>
											);
										} else if (allowed && this.state.errorCode) {
											if (this.state.refreshData) {
												return <LoadingIndicator />;
											}
											return (
												<div className="four-col body h3 bottom-margin">
													{getErrorMessage(this.state.errorCode, () =>
														this.onViewUpdate('refresh', null)
													)}
												</div>
											);
										} else {
											return <SiteMap notFound={true} class={'four-col'} />;
										}
									}
								}}
							</LoginRequired>
						</TicketsContext.Provider>
					</div>
				</div>
				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);

import React from 'react';
import RankingBar from './RankingBar';
import PowerRanking from 'appdir/components/common-ui/PowerRanking/PowerRanking';
const DeterminingFactors = props => {
	let { data } = props;

	//logger.log('[DeterminingFactors] data:%o', data);
	return (
		<div className="determining-factors-container">
			{/* <PowerRanking data={{ value: data.powerrank }}></PowerRanking> */}
			<div className="graph-title">Determining Factors</div>
			{data.determiningFactors.map((rankingData, i) => {
				return <RankingBar key={i} data={rankingData} />;
			})}
		</div>
	);
};

export default DeterminingFactors;

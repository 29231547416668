/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import PowerIndexLeaderboard from './PowerIndexLeaderboard';
const op = require('object-path');

/**
 * -----------------------------------------------------------------------------
 * React Component: PowerIndexLeaderboard
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['PowerIndexLeaderboard'],
		stubs: state['Config'].stubPages,
		flagImagePathSmall: op.get(state['Config'], 'otherData.flagImagePathSmall'),
		sharedDataConfig: state['Config'].sharedDataConfig,
		scoringData: state['Config'].scoringData,
		playerImagePath: op.get(state['Config'], 'otherData.playerProfileImagePath'),
		favourites: state['Controller'].favourites,
		powerIndex: state['Tournament']?.data?.powerIndex,
		players: state['Tournament']?.data?.players,
		drawAnalysisData: state['Tournament']?.data?.drawAnalysis,
		enabled: state['Config']?.enabled,
		
		// powerIndexMatchupData: state['CommonData']['powerIndexMatchup'], - not used
		powerIndexTrendingData: state['CommonData']['powerIndexTrending'],
		playerStatusData: state['CommonData']['playerStatus'],
		playerMatchesData: state['CommonData']['playerMatches'],
		matchInsightsMatchesData: state['CommonData']['matchInsightsMatches'],
		// matchInsightsData: state['CommonData']['matchInsights'],  - not used
		// matchInsightsMatchupData: state['CommonData']['matchInsightsMatchup'], - not used
		windowSize: state['PageHeader'].windowSize,
		currentDay: state['ActiveData']['currentDay'],
		pathToTheFinal: state['PathToTheFinal'],
		drawData: op.get(state['Tournament'], 'data.draw', null),
		Router: state['Router'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	unmount: () => dispatch(deps.actions.PowerIndexLeaderboard.unmount()),
	pathToTheFinalUnmount: data => dispatch(deps.actions.PathToTheFinal.unmount(data)),
	pathToTheFinalUpdate: data => dispatch(deps.actions.PathToTheFinal.update(data)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
	getDraws: event => dispatch(deps.actions.Tournament.getDraws({ event: event })),
	getPlayerList: () => dispatch(deps.actions.Tournament.getPlayerList()),
	getPowerIndex: eventId => dispatch(deps.actions.Tournament.getPowerIndex({ eventId: eventId })),
	getPowerIndexTrends: () => dispatch(deps.actions.Tournament.getPowerIndexTrends()),
	getDrawAnalysis: (event, playerId) =>
		dispatch(deps.actions.Tournament.getDrawAnalysis({ eventId: event, playerId: playerId })),
	getMatchInsightsAvailable: () => dispatch(deps.actions.Tournament.getMatchInsightsAvailable()),
	getPowerIndexMatchup: match => dispatch(deps.actions.Tournament.getPowerIndexMatchup({ matchId: match })),
	getPlayerStatus: playerId => dispatch(deps.actions.Tournament.getPlayerStatus({ playerId: playerId })),
	getPlayerMatches: playerId => dispatch(deps.actions.Tournament.getPlayerMatches({ playerId: playerId })),
	clearTournament: () => dispatch(deps.actions.Tournament.clearAll()),
	showPathToTheFinal: (playerId, event) => dispatch(deps.actions.PathToTheFinal.showPathToTheFinal(playerId, event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PowerIndexLeaderboard);

import React from 'react';
import LeaderboardData from 'appdir/components/pages/PowerIndexLeaderboard/elements/LeaderboardData';
import LeaderboardRow from 'appdir/components/pages/PowerIndexLeaderboard/elements/LeaderboardRow';
import DeterminingFactors from 'appdir/components/pages/PowerIndexLeaderboard/elements/DeterminingFactors';
import PlayerImage from 'appdir/components/common-ui/PlayerImage';
import RankingMovement from 'appdir/components/common-ui/PowerRanking/RankingMovement';

const PowerRankingTest = props => {
	const favourites = ({} = props.data);
	const data = {
		determiningFactors: [
			{ type: 'single', title: 'Recent Performance', value: 35 },
			{ type: 'single', title: 'Media Volume', value: 65 },
			{ type: 'double', title: 'Sentiment', value: -0.2 },
		],
		rankOverTime: {
			graphData: {
				labels: ['28', '29', '30', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
				series: [
					{
						name: 'atp-series',
						className: 'ct-series-a atp-series',
						data: [8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8],
					},
					{
						name: 'powerrank-series',
						className: 'ct-series-b  powerrank-series',
						data: [12, 4, 4, 4, 2, 2, 2, 1, 5, 6, 7, 7, 7, 7],
					},
					// {
					// 	name: 'lastmatch-series',
					// 	className: 'ct-series-c lastmatch-series',
					// 	data: [6.5, null, null, null, null, null, null, null, null, null, null, null, null, null],
					// }
				],
			},
			lastMatchPlayed: '03',
		},
		playerImageData: {
			'player-image': '//images.wimbledon.com/square/<id>.jpg'.replace('<id>', 'atpre44'),
			style: 'regular',
		},
	};

	const playerStatusData = {
		origin: 'leaderboard', // values can be leaderboard, hp_fav, hp_picks
		playerStatus: {
			playerID: 'wta230234',
			playerLastName: 'Williams',
			playerFirstName: 'Serena',
			playerTvName: 'S. Williams',
			displayMatch: '2601',
			inOut: 'in',
			matches: [
				{
					order: 2,
					match_id: '2601',
					eventName: "Ladies' Singles",
					shortEventName: "Ladies' Singles",
					eventCode: 'LS',
					courtName: 'Centre Court',
					shortCourtName: 'Centre Court',
					courtId: 'A',
					roundCode: 'S',
					roundName: 'Semi-Finals',
					roundNameShort: 'SF',
					dayNum: '18',
					dayMessage: 'Day 10 Thursday 11 July',
					dayMessageShort: 'Day 10 Thu 11 July',
					status: null,
					statusCode: 'B',
					conjunction: 'v',
					startTime: '1:00pm',
					notBefore: null,
					comment: null,
					team1: [
						{
							firstNameA: 'Serena',
							lastNameA: 'Williams',
							displayNameA: 'S. Williams',
							nationA: 'USA',
							idA: 'wta230234',
							firstNameB: null,
							lastNameB: null,
							displayNameB: null,
							nationB: null,
							idB: null,
							seed: 11,
							won: false,
						},
					],
					team2: [
						{
							firstNameA: 'Barbora',
							lastNameA: 'Strycova',
							displayNameA: 'B. Strycova',
							nationA: 'CZE',
							idA: 'wta190879',
							firstNameB: null,
							lastNameB: null,
							displayNameB: null,
							nationB: null,
							idB: null,
							seed: null,
							won: false,
						},
					],
					shortScore: null,
				},
				{
					order: 3,
					match_id: '5301',
					eventName: 'Mixed Doubles',
					shortEventName: 'Mixed Doubles',
					eventCode: 'MX',
					courtName: 'No.2 Court',
					shortCourtName: 'No.2 Court',
					courtId: 'C',
					roundCode: '3',
					roundName: 'Third Round',
					roundNameShort: '3R',
					dayNum: '17',
					dayMessage: 'Day 9 Wednesday 10 July',
					dayMessageShort: 'Day 9 Wed 10 July',
					status: 'Completed',
					statusCode: 'D',
					conjunction: 'defeated',
					startTime: '11:00am',
					notBefore: null,
					comment: null,
					team1: [
						{
							firstNameA: 'Bruno',
							lastNameA: 'Soares',
							displayNameA: 'B. Soares',
							nationA: 'BRA',
							idA: 'atps938',
							firstNameB: 'Nicole',
							lastNameB: 'Melichar',
							displayNameB: 'N. Melichar',
							nationB: 'USA',
							idB: 'wta316713',
							seed: 1,
							won: true,
						},
					],
					team2: [
						{
							firstNameA: 'Andy',
							lastNameA: 'Murray',
							displayNameA: 'A. Murray',
							nationA: 'GBR',
							idA: 'atpmc10',
							firstNameB: 'Serena',
							lastNameB: 'Williams',
							displayNameB: 'S. Williams',
							nationB: 'USA',
							idB: 'wta230234',
							seed: null,
							won: false,
						},
					],
					shortScore: '6-3 4-6 6-2',
				},
			],
		},
	};

	const powerrankMovementUp = {
		rank: 8,
		movement: 3,
	};
	const powerrankMovementDown = {
		rank: 6,
		movement: -3,
	};

	const leaderboardRowUp = {
		type: 'row',
		atpRank: 22,
		rank: 7,
		movement: 3,
		flagPath: '/assets/images/flags/<countryCode>_sm.gif',
		countryCode: 'RUS',
		countryName: 'Russia',
		shortName: 'A. Rublev',
		playerName: 'Andrey Rublev',
		playerId: 'atpre44',
	};

	const leaderboardRowDown = {
		type: 'row',
		atpRank: 10,
		rank: 2,
		movement: -4,
		flagPath: '/assets/images/flags/<countryCode>_sm.gif',
		countryCode: 'GER',
		countryName: 'Germany',
		shortName: 'P. Kohlschreiber',
		playerName: 'Philipp Kohlschreiber',
		playerId: 'atpk435',
	};

	const leaderboardRow = {
		type: 'row',
		atpRank: 8,
		rank: 6,
		movement: 0,
		flagPath: '/assets/images/flags/<countryCode>_sm.gif',
		countryCode: 'USA',
		countryName: 'United States',
		shortName: 'C. Gauff',
		playerName: 'Cori Gauff',
		playerId: 'wta328560',
	};

	const leaderboardHeader = {
		type: 'header',
	};

	const leaderboardSubheader = {
		type: 'subheader',
	};

	logger.log('[PowerRankingTest] render - props:%o', props);

	return (
		<>
			<p>COMPONENTS</p>
			<hr />
			<DeterminingFactors data={data.determiningFactors} />
			<hr />
			{/* <RankOverTime data={data.rankOverTime} /> */}
			<hr />
			<p>Power Rank Movement</p>
			<RankingMovement data={powerrankMovementDown} />
			<RankingMovement data={powerrankMovementUp} />
			<hr />
			<div className="powerrank-section">
				<PlayerImage attributes={data.playerImageData} />
			</div>
			<hr />
			<div className="nextmatch-container">Next match placeholder</div>
			<br />
			<br />
			<hr />
			<br />
			<br />
			<p>LEADER BOARD TABLE</p>
			<hr />
			<div className="leaderboard-table">
				<LeaderboardRow data={leaderboardHeader}>
					<LeaderboardData data={data} />
				</LeaderboardRow>
				{favourites.players && favourites.players.length > 0 && favourites.show ? (
					<>
						<LeaderboardRow data={leaderboardSubheader}>Favourites</LeaderboardRow>
						<LeaderboardRow data={leaderboardRowUp}>
							<LeaderboardData data={data} />
						</LeaderboardRow>
					</>
				) : null}
				<LeaderboardRow data={leaderboardSubheader}>Full Leaderboard</LeaderboardRow>
				<LeaderboardRow data={leaderboardRowUp}>
					<LeaderboardData data={data} />
				</LeaderboardRow>
				<LeaderboardRow data={leaderboardRowDown}>
					<LeaderboardData data={data} />
				</LeaderboardRow>
				<LeaderboardRow data={leaderboardRow}>
					<LeaderboardData data={data} />
				</LeaderboardRow>
			</div>
		</>
	);
};
export default PowerRankingTest;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';

import PlayerImage from 'appdir/components/common-ui/PlayerImage';

import DeterminingFactors from './DeterminingFactors';
import RankOverTime from './RankOverTime';
import PowerRanking from 'appdir/components/common-ui/PowerRanking/PowerRanking';
import PlayerStatus from 'appdir/components/common-ui/MyPlayers/PlayerStatus';
import DrawAnalysis from 'appdir/components/common-ui/DrawAnalysis';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
// import SlamtrackerButton from 'appdir/components/common-ui/SlamtrackerButton';

import {
	isSlamTrackerActive,
	isPostMatch,
	getSinglesEventCodeByPlayerId,
} from 'appdir/components/pages/DrawsPage/DrawsUtils';
import { values } from 'appdir/main';
import { generateStatusText, getSinglesMatch } from 'appdir/components/general/Util/MatchUtil';
import { doMeasurement } from 'appdir/components/general/Analytics';
const op = require('object-path');

const LeaderboardData = (props = {}) => {
	const { data = {} } = props;
	const playerDrawAnalysisData = data?.playerDrawAnalysisData;
	logger.log('[LeaderboardData] data:%o', data);

	const dispatch = useDispatch();

	const displayMatchDetails = () => {
		try {
			return getSinglesMatch(props.data.playerStatusData);
		} catch (e) {
			return null;
		}
	};
	const hasStatus = () => {
		try {
			return generateStatusText(displayMatchDetails());
		} catch (e) {
			return null;
		}
	};
	const matchId = props.data.playerStatusData.displayMatch;
	const hasInsight = op.get(data, 'matchInsightsMatches.matches')
		? data.matchInsightsMatches.matches.indexOf(matchId) !== -1
		: false;

	const statusText = hasStatus();

	const openPathToTheFinal = (id, event = getSinglesEventCodeByPlayerId(playerDrawAnalysisData?.playerId)) => {
		if (id) {
			let scrollTop =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: (document.documentElement || document.body.parentNode || document.body).scrollTop;

			doMeasurement(values.powerIndexTitle, 'Path To The Final', [], [{ player_id: id }]);
			dispatch(deps.actions.PathToTheFinal.showPathToTheFinal(id, event, scrollTop));
		}
	};

	logger.log(
		'[LeaderboardData] matchId:%o, statusText:%o, hasInsight:%o',
		matchId,
		statusText,
		hasInsight
	);

	return (
		<div className="powerrank-data-container">
			<div className="powerrank-data-content">
				<div className="player-image-wrapper">
					<PlayerImage attributes={data.playerImageData} />
					{/* NextMatch: tablet+ */}
					<div className="nextmatch-container">
						<ErrorBoundary message="">
							{statusText ? (
								<PlayerStatus
									preMatchInsight={hasInsight}
									statusText={{ ...statusText }}
									matchId={matchId}
									slamtrackerActive={isSlamTrackerActive(props.data?.playerStatusData?.matches?.[0])}
									statusCode={props.data?.playerStatusData?.matches?.[0]?.statusCode}
									postMatch={isPostMatch(props.data?.playerStatusData?.matches?.[0])}
									page="powerindex"
								/>
							) : null}
						</ErrorBoundary>
					</div>
				</div>
				<div className="right">
					<PowerRanking data={{ value: data.powerrank }}></PowerRanking>

					{ /** above mobile display (set in CSS) */
					playerDrawAnalysisData && (
						<ErrorBoundary message="">
							<DrawAnalysis
								attributes={{
									style: "mobile-hide",
									playerDrawAnalysisData,
									playerStatusData: props.data.playerStatusData,
									metricsPage: values.powerIndexTitle,
									view: 'powerindex',
									callbackFn: playerId => openPathToTheFinal(playerId),
								}}
							/>
						</ErrorBoundary>
					)}
				</div>
			</div>

			
			{ /** mobile only display (set in CSS) */
				playerDrawAnalysisData && (
					<ErrorBoundary message="">
						<DrawAnalysis
							attributes={{
								style: "mobile-show",
								playerDrawAnalysisData,
								playerStatusData: props.data.playerStatusData,
								metricsPage: values.powerIndexTitle,
								view: 'powerindex',
								callbackFn: playerId => openPathToTheFinal(playerId),
							}}
						/>
					</ErrorBoundary>
				)
			}
			

			<div className="powerrank-graph-container">
				{data.determiningFactors ? (
					<DeterminingFactors
						data={{ determiningFactors: data.determiningFactors, powerrank: data.powerrank }}
					/>
				) : null}

				{data.rankOverTime ? <RankOverTime data={data.rankOverTime} /> : null}
			</div>

			{/* NextMatch: mobile only */}
			<div className="nextmatch-container mobile">
				<ErrorBoundary message="">
					{statusText ? (
						<PlayerStatus
							preMatchInsight={hasInsight}
							statusText={{ ...statusText }}
							matchId={matchId}
							slamtrackerActive={isSlamTrackerActive(props.data?.playerStatusData?.matches?.[0])}
							statusCode={props.data?.playerStatusData?.matches?.[0]?.statusCode}
							page="powerindex"
						/>
					) : null}
				</ErrorBoundary>
			</div>
		</div>
	);
};

export default LeaderboardData;

import PowerIndexLeaderboard from './index';
import { MENU_ITEMS } from 'appdir/components/general/Util';
import deps from 'dependencies';

export default {
	path: ['/:lang/powerindex23/index.html', '/:lang/powerindex23/'],
	exact: true,
	component: PowerIndexLeaderboard,
	section: MENU_ITEMS.players,
	load: params => deps.actions.PowerIndexLeaderboard.mount(params),
};

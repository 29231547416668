import React from 'react';
import WimLink from 'appdir/components/general/WimLink';

const IndexList = ({ data }) => {
	return (
		<div className="content-main content ">
			<div className="column-layout content">
				<div className="two-col margin">
					<strong>IndexList</strong>
				</div>
				
				<div className="two-col margin">
					<WimLink to="/testwim/commonui">CommonUI</WimLink>
				</div>

				<div className="two-col margin">
					<WimLink to="/testwim/columns">Column Layout</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/buttons">Buttons</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/tournament">Tournament Data</WimLink>
				</div>

				<div className="two-col margin">
					<WimLink to="/testwim/recplayers">Recommended Player</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/screens">Gigya Screens</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/pdfreader">PDF Reader</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/layout">Page Layout (Generic)</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/cms">CMS</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/restaurant">Restaurant Buttons </WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/pde">PDE Components</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/roleicons">Role Icons</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/form">Generic Form Fields</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/pdf">PDF links</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/powerranking">Power Ranking</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/moment">Time Library</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/pathtothefinal">Path To The Final</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/favourting">Favouriting</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/measure">Measure</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/networktest">Network Test</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/storyteller">Storyteller</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/headshots">Headshots</WimLink>
				</div>
				<div className="two-col margin">
					<WimLink to="/testwim/countries">Countries</WimLink>
				</div>
			</div>
		</div>
	);
};

export default IndexList;

/*


*/

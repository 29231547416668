/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { handleColumns } from 'appdir/components/general/Util';

import MainNav from 'appdir/components/general/MainNav';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import Time from 'appdir/components/common-ui/Time';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Template from '../../Template';
import GigyaScreens from './GigyaScreens';
import Buttons from './Buttons';
import PDFReader from 'appdir/components/cms/PDFReader';
import PlayerStatusWidget from 'appdir/components/cms/PlayerStatusWidget';
import EventNavigator from 'appdir/components/cms/EventNavigator';
import Columns from './Columns';
import RoleIconsSample from './RoleIconsSample';
import CMS from './CMS';
import StorytellerPage from './StorytellerPage';
import PageLayout from './PageLayout';
import RTW from './RTW';
import PDFs from './PDFs';
import RecommendedPlayer from './RecommendedPlayer';
import FormFields from './FormFields';
import WimLink from 'appdir/components/general/WimLink';
import { TestContext } from './context';
import ChampionshipsRegistration from 'appdir/components/common-ui/ChampionshipsRegistration';
import RoleIcons from 'appdir/components/cms/RoleIcons';
import SimpleComponent from 'appdir/components/cms/SimpleComponent';
import PowerRankingTest from './PowerRankingTest';
import SVG from './SVG';
import RestaurantButtons from './RestaurantButtons';
import Moment from './Moment';
import Tournament from './Tournament';
import Measure from './Measure';
import IndexList from './IndexList';
import PathToTheFinalTest from './PathToTheFinalTest';
import PathToTheFinalStandaloneTest from './PathToTheFinalStandaloneTest';
import NetworkTest from './NetworkTest';
import VideoTest from './VideoTest';
import Favourting from 'appdir/components/general/Favouriting/index.js';
import PromoFeature from 'appdir/components/general/PromoFeature/index.js';
import Interests from 'appdir/components/general/Onboarding/Interests.js';
import RegistrationCompletion from 'appdir/components/general/Gigya/screens/RegistrationCompletion.js';
import MobileWebFooter from 'appdir/components/cms/MobileWebFooter';
import Headshots from './Headshots';
import CommonUI from './CommonUI';
import Countries from './Countries';

/**
 * -----------------------------------------------------------------------------
 * React Component: TestPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Gigya'],
		...state['TestPage'],
		Config: state['Config'],
		windowSize: state['PageHeader'].windowSize,
		favourites: state['Controller'].favourites,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.TestPage.mount()),
});

class TestPage extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		logger.log('----------------------------------------------------------------------------');
		logger.log(`${this.constructor.name}.constructor()`);

		//logger.log('[TestPage] state:%o', this.state);
	}

	componentDidMount() {
		this.props.mount();
		logger.log(`${this.constructor.name}.componentDidMount()`);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log(`[TestPage] componentWillReceiveProps - props:%o`, nextProps);

		let comp = { component: nextProps.location.pathname.replace('/testwim/', '') };

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				...comp,
			};
		});
	}

	getTestComponent() {
		if (this.state.component == 'page') {
			return <div>Test Page</div>;
		} else if (this.state.component == 'recplayers') {
			return <RecommendedPlayer />;
		} else if (this.state.component == 'screens') {
			return <GigyaScreens />;
		} else if (this.state.component == 'buttons') {
			return <Buttons />;
		} else if (this.state.component == 'pdfreader') {
			return (
				<div style={{ width: '50%', height: '500px', display: 'flex' }}>
					<PDFReader
						data={{
							showPDF: true,
							text: 'Mens Singles Draw',
							link: 'https://www-cdt.wimbledon.com/en_GB/scores/draws/2023_MS_draw.pdf',
						}}
					/>
				</div>
			);
		} else if (this.state.component == 'columns') {
			return <Columns />;
		} else if (this.state.component == 'layout') {
			return <PageLayout />;
		} else if (this.state.component == 'cms') {
			return <CMS currentUser={this.state.currentUser} />;
		} else if (this.state.component == 'restaurant') {
			return <RestaurantButtons currentUser={this.state.currentUser} />;
		} else if (this.state.component == 'rtw') {
			return <RTW />;
		} else if (this.state.component == 'svg') {
			return <SVG />;
		} else if (this.state.component == 'playerwidget' || this.state.component == 'pde') {
			return (
				<Fragment>
					<div className="">
						<SimpleComponent tag="ChampionshipRegistration" />
					</div>
					<div className="four-col">
						<SimpleComponent tag="PdeEventNavigator" />
					</div>
					<div className="four-col">
						<SimpleComponent tag="PlayerStatusComponent" playerId={this.state.playerid} />
					</div>
					<div className="four-col">
						<RoleIcons role="player" tag="private" />
					</div>
				</Fragment>
			);
		} else if (this.state.component == 'roleicons') {
			return <RoleIconsSample currentUser={this.state.currentUser} />;
		} else if (this.state.component == 'form') {
			return <FormFields />;
		} else if (this.state.component == 'pdf') {
			return <PDFs />;
		} else if (this.state.component == 'powerranking') {
			return <PowerRankingTest data={this.props.favourites} />;
		} else if (this.state.component == 'error') {
			return <Error />;
		} else if (this.state.component == 'moment') {
			return <Moment />;
		} else if (this.state.component == 'tournament') {
			return <Tournament />;
		} else if (this.state.component == 'pathtothefinal') {
			return <PathToTheFinalTest />;
		} else if (this.state.component == 'pathtothefinalstandalone') {
			return <PathToTheFinalStandaloneTest />;
		} else if (this.state.component == 'favourting') {
			return <Favourting />;
		} else if (this.state.component == 'PromoFeature') {
			return <PromoFeature />;
		} else if (this.state.component == 'Interests') {
			return <Interests />;
		} else if (this.state.component == 'measure') {
			return <Measure />;
		} else if (this.state.component == 'RegistrationCompletion') {
			return <RegistrationCompletion />;
		} else if (this.state.component == 'networktest') {
			return <NetworkTest />;
		} else if (this.state.component == 'videotest') {
			return <VideoTest />;
		} else if (this.state.component == 'storyteller') {
			return <StorytellerPage />;
		} else if (this.state.component == 'headshots') {
			return <Headshots />;
		} else if (this.state.component == 'commonui') {
			return <CommonUI />;
		} else if (this.state.component == 'countries') {
			return <Countries />;
		} else {
			return <IndexList />;
		}
		//  else if ((this.state.component = 'eventselector')) {
		// 	return <EventSelector history={this.props.history} />;
		// }

		return null;
	}

	componentDidUpdate() {
		handleColumns();
	}

	render() {
		// logger.log('[TestPage] render - state:%o', this.state);

		let header_propsData = {
			headerType: 'generic',
			title: 'Test Page',
			shortTitle: 'Test Page',
			shortTitle: '',
			titleElem: '.landing--header',
			pageTitle: 'News',
			htsearch: 'News',
		};

		let attributes = {
			contentId: '5771769820001',
			date: 9030940340,
			dayId: '',
			type: 'Video',
			title: "Throwback Thursday: Isner's aces record",
			shortTitle: "Throwback Thursday: Isner's aces record",
			url: '/en_GB/news/video/5771769820001.html',
			images: {
				small:
					'http://wimbledonprogressivedl.edgesuite.net/2014/thumbnails/3506358525001/2018/04/3506358525001_5771793219001_5771769820001-vs.jpg?pubId=3506358525001',
				medium:
					'http://wimbledonprogressivedl.edgesuite.net/2014/thumbnails/3506358525001/2018/04/3506358525001_5771793219001_5771769820001-vs.jpg?pubId=3506358525001',
				large:
					'http://wimbledonprogressivedl.edgesuite.net/2014/thumbnails/3506358525001/2018/04/3506358525001_5771793219001_5771769820001-vs.jpg?pubId=3506358525001',
			},
		};

		return (
			<Template>
				<Header attributes={header_propsData} />

				<PageHeader attributes={header_propsData} />
				<TestContext.Provider value={this.contextValues}>{this.getTestComponent()}</TestContext.Provider>

				<Footer mobileWebFooter />

				<MobileWebFooter />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);

import React, { Component } from 'react';
import { values } from 'appdir/main';
import RankingMovement from 'appdir/components/common-ui/PowerRanking/RankingMovement';
import FavouriteStar from 'appdir/components/common-ui/FavouriteStar';
import WimLink from 'appdir/components/general/WimLink';
import { getQSParams } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import { doMeasurement } from 'appdir/components/general/Analytics';

class LeaderboardRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			favs: this.props.data.appFavs,
			//favs: window.location.search ? getQSParams(window.location.search, 'filterPlayers').split(',') : []
		};
		this.toggleRow = this.toggleRow.bind(this);
		this.sortColumn = this.sortColumn.bind(this);
		this.updateFavs = this.updateFavs.bind(this);

		//logger.log('[LeaderboardRow] constructor this.props:%o', this.props);
		//this.state.favs = window.location.search ? getQSParams(window.location.search, 'filterPlayers').split(',') : [];
	}

	componentDidUpdate(prevProps, prevState) {
		/** tab is changed b/w Gentlemens and Ladies, reset the selected column */
		if(this.props?.data?.selectedTab !== prevProps?.data?.selectedTab && this.props?.data?.selectedTab !== this.state?.sortedColumn) {
			this.setState({
				sortedColumn: null
			})
		}
	}

	isWebviewFav() {
		logger.log(
			'[LeaderboardRow] - isWebviewFav - favs:%o, %o:%o',
			this.state.favs,
			this.props.data.playerId,
			this.state.favs.indexOf(this.props.data.playerId) !== -1
		);
		return this.state.favs.indexOf(this.props.data.playerId) !== -1;
	}

	toggleRow(toggle) {
		logger.log('[LeaderboardRow] toggleRow props:%o, toggle:%o', this.props, toggle);
		this.props.data.expandCallback(this.props.data.playerId, toggle, this.props.data.expandType);

		let measureArgs = [];
		let contextData = toggle ? [{ action: toggle }, { pi_playerID: this.props.data.playerId }] : [];

		if (values.webview) {
			measureArgs = [toggle];
			contextData = toggle ? [{ pi_playerID: this.props.data.playerId }] : [];
		}
		doMeasurement(`${values.powerIndexTitle}`, 'toggleRow', measureArgs, contextData);
	}

	sortColumn(which) {
		let measureArgs = [];
		let contextData = [{ item: which == 'atprank' ? 'tourRank' : which }];

		if (values.webview) {
			measureArgs = [which == 'atprank' ? 'tourRank' : which];
			contextData = [];
		}
		doMeasurement(`${values.powerIndexTitle}`, 'sortColumn', measureArgs, contextData);

		this.setState(
			{
				sortedColumn: which,
			},
			() => {
				this.props.data.sortCallback(which);
			}
		);
	}

	updateFavs() {
		let newFavs = Object.assign([], this.state.favs);
		let shouldAdd = true;
		let contextKey = 'favorite_add';
		logger.log('[LeaderboardRow] - updateFavs - favs:%o', this.state.favs);
		if (this.isWebviewFav()) {
			// remove fav
			newFavs = this.state.favs.filter(fav => fav !== this.props.data.playerId);
			shouldAdd = false;
			contextKey = 'favorite_remove';
		} else {
			// add fav
			newFavs.push(this.props.data.playerId);
		}

		let measureArgs = [];
		let contextData = [{ action: shouldAdd ? 'Add' : 'Remove' }, { [contextKey]: this.props.data.playerId }];

		if (values.webview) {
			measureArgs = [shouldAdd ? 'Add' : 'Remove'];
			contextData = [{ [contextKey]: this.props.data.playerId }];
		}

		//logger.log('[LeaderboardRow] - updateFavs - newFavs:%o',newFavs);
		doMeasurement(`${values.powerIndexTitle}`, 'Favorite', measureArgs, contextData);

		this.setState(
			{
				favs: newFavs,
			},
			() => {
				if (window.webview) {
					this.props.data.appFavCallback(this.props.data.playerId, shouldAdd);
				}
			}
		);
	}

	/** 
	 * only display draw analysis label if the event is still going 
	 * and the player is still in the event
	 * 
	 * props.playerEventStatus ----- "in" <-- default, "out",  "won"
	 */
	getDrawAnalysisCol = () => {
		let showDrawAnalysis = this.props?.data?.playerEventStatus !== "out" && this.props?.data?.playerEventStatus !== "won";

		return(
			<div
				className={`leaderboard-cell aidraw ${
					this.props?.data?.drawAnalysis?.drawLabel && showDrawAnalysis ? this.props?.data?.drawAnalysis?.drawLabel : 'no-bullet'
				}`}>
				<span>{ showDrawAnalysis ? this.props?.data?.drawAnalysis?.drawLabel : "" }</span>
			</div>
		)
	}

	render() {
		let { data = {} } = this.props;
		// logger.log(
		// 		'[LeaderboardRow] render playerName:%o, drawAnalysis:%o',
		// 		data.playerName,
		// 		data.drawAnalysis
		// 	);

		return (
			<>
				{data.type === 'row' ? (
					<>
						<div className={`leaderboard-row ${data.expand ? 'expand' : 'collapse'}`}>
							<div className={`leaderboard-cell expand`} onClick={() => this.toggleRow(!data.expand)}>
								<div className={`${data.expand ? 'open' : 'close'}`}></div>
							</div>
							<div className="leaderboard-cell powerrank">
								<RankingMovement data={{ rank: data.rank, movement: data.movement }} />
							</div>
							<div className="leaderboard-cell atprank">{data.atpRank}</div>
							<div className="leaderboard-cell playername">
								<img
									alt={data.countryCode}
									src={`${data.flagPath?.replace('<code>', data.countryCode)}`}
								/>
								<span className="shortname">
									<WimLink to={`/en_GB/players/overview/${data.playerId}.html`}>
										{data.shortName}
									</WimLink>
								</span>
								<span className="longname">
									<WimLink to={`/en_GB/players/overview/${data.playerId}.html`}>
										{data.playerName}
									</WimLink>
								</span>
							</div>
							<div className="leaderboard-cell country">
								<img
									alt={data.countryCode}
									src={`${data.flagPath?.replace('<code>', data.countryCode)}`}
								/>
								{data.countryName}
							</div>
							{ /** display or hide the draw analysis label - only players in the tournament to display during the event */
								 (this.props?.data?.enabled && this.props?.data?.playerEventStatus) && (
									this.getDrawAnalysisCol()
								 )
							}
							<div className="leaderboard-cell star">
								{window.webviewPlatform ? (
									<WimLink
										to={
											!this.isWebviewFav()
												? `/addPlayerFav/${data.playerId}.html`
												: `/removePlayerFav/${data.playerId}.html`
										}
										external={true}>
										<span
											onClick={() => this.updateFavs()}
											className={`favorite ${
												this.isWebviewFav() ? 'favorite favorited yellow' : 'favorite green'
											}`}></span>
									</WimLink>
								) : (
									<FavouriteStar
										id={data.playerId}
										type="players"
										unFavColor="green"
										favColor="green"
										alwaysShow={true}
									/>
								)}
							</div>
						</div>
						<div className={`leaderboard-data${data.expand ? ' expand' : ' collapse'}`}>
							{data.expand ? this.props.children : null}
						</div>
					</>
				) : null}
				{data.type === 'header' ? (
					<div className="leaderboard-header">
						<div className="leaderboard-cell expand"></div>
						<div
							className={`leaderboard-cell powerrank ${
								this.state.sortedColumn === 'powerrank' || !this.state.sortedColumn ? 'sort' : ''
							}`}
							onClick={() => this.sortColumn('powerrank')}>
							<span>Power Index</span>
						</div>
						<div
							className={`leaderboard-cell atprank ${
								this.state.sortedColumn === 'atprank' ? 'sort' : ''
							}`}
							onClick={() => this.sortColumn('atprank')}>
							<span>{data.selectedTab} Ranking</span>
						</div>
						<div
							className={`leaderboard-cell playername ${
								this.state.sortedColumn === 'playername' ? 'sort' : ''
							}`}
							onClick={() => this.sortColumn('playername')}>
							<span>Player</span>
						</div>
						<div
							className={`leaderboard-cell country ${
								this.state.sortedColumn === 'country' ? 'sort' : ''
							}`}
							onClick={() => this.sortColumn('country')}>
							<span>Country</span>
						</div>
						{ /** if enabled, check the first player's playerEventStatus to prevent
						 *    the AI Draw Analysis table header text from flashing in case of no label to display
						 */
							this.props?.data?.enabled && (
								<div
									className={`leaderboard-cell aidraw ${
										this.state.sortedColumn === 'aidraw' ? 'sort' : ''
									}`}
									onClick={() => this.sortColumn('aidraw')}>
									{!data.hideAidrawHdr && data.playerEventStatus && <span>AI Draw Analysis</span>}
								</div>
							)
						}
						
						<div className={`leaderboard-cell star`}>Fav</div>
					</div>
				) : null}
				{data.type === 'subheader' ? <div className="leaderboard-subheader">{this.props.children}</div> : null}
			</>
		);
	}
}

LeaderboardRow.defaultProps = {
	// type: 'row',
	// atpRank: false,
	// rank: 7,
	// movement: 0,
	// flagPath: '/assets/images/flags/<countryCode>_sm.gif',
	// countryCode: 'RUS',
	// countryName: 'Russia',
	// playerName: 'Andrey Rublev',
	// playerId: 'atpre44',
};

export default LeaderboardRow;
